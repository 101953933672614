function createElement(tagName, parent) {
  var elt = document.createElementNS('http://www.w3.org/2000/svg', tagName);
  if (parent) {
    parent.appendChild(elt);
  }
  return elt;
}

/**
 * getTextSize
 * @desc 计算文本字体大小.
 * @param {Array} text 文本
 * @param {String} w 文本宽度
 * @param {String} h 文本高度
 * @param {String} fontName 文本字体样式
 * @param {String} fontUnit 文本字体单位
 *
 */
function getTextSize(text, w, h, fontName, fontUnit) {
  let size = [];
  let c = document.getElementById('myCanvas');
  let ctx = c.getContext('2d');
  for (let i = 10; i < 1000; i++) {
    ctx.font = `${i}${fontUnit} ${fontName || '宋体'}`;
    let bound = ctx.measureText(text);
    if (i <= h && bound.width <= w) {
      size.push(i);
    } else {
      break;
    }
  }
  let res = size.length ? Math.max(...size) : 10;
  return res;
}

/**
 * createLinearGradient
 * @desc 线性渐变.
 * @param {Array} colors 渐变色组
 * @param {String} direction 渐变方向
 *
 */
function createLinearGradient(colors, direction) {
  var gradient = createElement('linearGradient');
  gradient.setAttribute('x1', '0%');
  gradient.setAttribute('y1', '0%');
  gradient.setAttribute('x2', '0%');
  gradient.setAttribute('y2', '0%');

  if (direction === null || direction === 'south') {
    gradient.setAttribute('y2', '100%');
  } else if (direction === 'east') {
    gradient.setAttribute('x2', '100%');
  } else if (direction === 'north') {
    gradient.setAttribute('y1', '100%');
  } else if (direction === 'west') {
    gradient.setAttribute('x1', '100%');
  }
  for (var i = 0; i < colors.length; i++) {
    const { color, offset, opacity } = colors[i];
    var stop = createElement('stop');
    stop.setAttribute('offset', offset);
    stop.setAttribute('stop-color', color);
    stop.setAttribute('stop-opacity', opacity);
    gradient.appendChild(stop);
  }
  let id = 'svg' + Math.random();
  gradient.setAttribute('id', id);
  return gradient;
}

/**
 * createRadialGradient
 * @desc 径向渐变.
 * @param {Array} colors 渐变色组
 * @param {String} direction 渐变方向
 *
 */
function createRadialGradient(colors, direction) {
  var gradient = createElement('radialGradient');
  gradient.setAttribute('cx', '50%');
  gradient.setAttribute('cy', '50%');
  gradient.setAttribute('fx', '50%');
  gradient.setAttribute('fy', '50%');

  for (var i = 0; i < colors.length; i++) {
    const { color, offset, opacity } = colors[i];
    var stop = createElement('stop');
    stop.setAttribute('offset', offset);
    stop.setAttribute('stop-color', color);
    stop.setAttribute('stop-opacity', opacity);
    gradient.appendChild(stop);
  }
  let id = 'svg' + Math.random();
  gradient.setAttribute('id', id);
  return gradient;
}

/**
 * createPattern
 * @desc 背景模式.
 * @param {String} tag 类型
 * @param {String} fillColor 填充色
 * @param {String} strokeColor 描边色
 *
 */
function createPattern(tag, fillColor, strokeColor) {
  var pattern = createElement('pattern');
  pattern.setAttribute('patternUnits', 'userSpaceOnUse');
  pattern.setAttribute('x', '0');
  pattern.setAttribute('y', '0');
  pattern.setAttribute('width', '7');
  pattern.setAttribute('height', '7');
  let id = 'svg' + Math.random();
  pattern.setAttribute('id', id);
  let rect = createElement('rect');
  rect.setAttribute('x', '0');
  rect.setAttribute('y', '0');
  rect.setAttribute('width', '7');
  rect.setAttribute('height', '7');
  rect.setAttribute('fill', fillColor);
  pattern.appendChild(rect);
  if (tag === 'forward') {
    let path = createElement('path');
    path.setAttribute('d', 'M0,0 l7,7');
    path.setAttribute('stroke', strokeColor);
    path.setAttribute('stroke-width', '1');
    let path1 = createElement('path');
    path1.setAttribute('d', 'M6,-1 l3,3');
    path1.setAttribute('stroke', strokeColor);
    path1.setAttribute('stroke-width', '1');
    let path2 = createElement('path');
    path2.setAttribute('d', 'M-1,6 l3,3');
    path2.setAttribute('stroke', strokeColor);
    path2.setAttribute('stroke-width', '1');
    pattern.appendChild(path);
    pattern.appendChild(path1);
    pattern.appendChild(path2);
  } else if (tag === 'backward') {
    let path = createElement('path');
    path.setAttribute('d', 'M7,0 l-7,7');
    path.setAttribute('stroke', strokeColor);
    path.setAttribute('stroke-width', '1');
    let path1 = createElement('path');
    path1.setAttribute('d', 'M1,-1 l-7,7');
    path1.setAttribute('stroke', strokeColor);
    path1.setAttribute('stroke-width', '1');
    let path2 = createElement('path');
    path2.setAttribute('d', 'M8,6 l-7,7');
    path2.setAttribute('stroke', strokeColor);
    path2.setAttribute('stroke-width', '1');
    pattern.appendChild(path);
    pattern.appendChild(path1);
    pattern.appendChild(path2);
  } else if (tag === 'crossDiag') {
    let path = createElement('path');
    path.setAttribute('d', 'M7,0 l-7,7');
    path.setAttribute('stroke', strokeColor);
    path.setAttribute('stroke-width', '1');
    let path1 = createElement('path');
    path1.setAttribute('d', 'M1,-1 l-7,7');
    path1.setAttribute('stroke', strokeColor);
    path1.setAttribute('stroke-width', '1');
    let path2 = createElement('path');
    path2.setAttribute('d', 'M8,6 l-7,7');
    path2.setAttribute('stroke', strokeColor);
    path2.setAttribute('stroke-width', '1');
    let path3 = createElement('path');
    path3.setAttribute('d', 'M0,0 l7,7');
    path3.setAttribute('stroke', strokeColor);
    path3.setAttribute('stroke-width', '1');
    let path4 = createElement('path');
    path4.setAttribute('d', 'M6,-1 l3,3');
    path4.setAttribute('stroke', strokeColor);
    path4.setAttribute('stroke-width', '1');
    let path5 = createElement('path');
    path5.setAttribute('d', 'M-1,6 l3,3');
    path5.setAttribute('stroke', strokeColor);
    path5.setAttribute('stroke-width', '1');
    pattern.appendChild(path);
    pattern.appendChild(path1);
    pattern.appendChild(path2);
    pattern.appendChild(path3);
    pattern.appendChild(path4);
    pattern.appendChild(path5);
  } else if (tag === 'horizontal') {
    let path = createElement('path');
    path.setAttribute('d', 'M7,0 l-7,0');
    path.setAttribute('stroke', strokeColor);
    path.setAttribute('stroke-width', '1');
    pattern.appendChild(path);
  } else if (tag === 'Vertical') {
    let path = createElement('path');
    path.setAttribute('d', 'M7,0 l0,7');
    path.setAttribute('stroke', strokeColor);
    path.setAttribute('stroke-width', '1');
    pattern.appendChild(path);
  }
  return pattern;
}

/**
 * render
 * @desc 数据转化.
 * @param {Object} data 模型数据
 *
 */
export class render {
  constructor(data) {
    // 检测非对象类型直接返回
    if (!data || Object.prototype.toString.call(data) !== '[object Object]') {
      return;
    }
    this.initData = data; // 原始数据
    this.data = null; // 新的数据
    this.xml = ''; // xml字符串
    this.base64 = ''; // base64
    this.width = 0; // 宽度
    this.height = 0; // 高度
    this.init();
    if (this.root) {
      this.base64 = this.svgToBase64(this.root);
      this.xml = this.svgToString(this.root);
    }
  }

  init() {
    try {
      const { icon, diagram, components, connects, extent } = this.initData;
      // 为空
      const isDiagramEmpty =
        diagram && !diagram.length && !components.length && !connects.length;
      const isIconEmpty = icon && !icon.length && !components.length;
      if (isDiagramEmpty || isIconEmpty) {
        return;
      }
      this.root = createElement('g');
      this.root.setAttribute('id', 'tongyuan');
      this.defs = createElement('defs', createElement('g', this.root));
      this.drawPane = createElement('g', this.root);
      this.width = Math.abs(extent[0][0] - extent[1][0]) + 2;
      this.height = Math.abs(extent[0][1] - extent[1][1]) + 2;
      if (components && components.length) {
        this.width += 40;
        this.height += 40;
      }
      this.data = [];
      if (connects) {
        this.data = this.data.concat(this.parseConnects(connects));
      }
      if (icon) {
        this.data = this.data.concat(this.parseDiagrams(icon));
      }
      if (diagram) {
        this.data = this.data.concat(this.parseDiagrams(diagram));
      }
      if (components) {
        this.data = this.data.concat(this.parseComponents(components));
      }
      // console.log(this.data, 'data');
      this.draw(this.data, this.drawPane);
      this.drawPane.setAttribute(
        'transform',
        `matrix(1,0,0,1,${this.width / 2},${this.height / 2})`
      );
    } catch (err) {
      console.log(err, 'err');
    }
  }

  // 解析组件-区分组件和端口 prevscale是为了计算线条的宽度
  parseComponents(components, type = 'component', prevscale = 1) {
    let nodes = [];
    components.forEach(item => {
      const isComponentVisible = type == 'component' && item.visible;
      const isPortVisible = type == 'port' && item.conditionValid;
      if (isComponentVisible || isPortVisible) {
        const {
          ident,
          extent,
          coordSysExtent,
          origin,
          rotation,
          typeName,
          connectable,
          description,
          extensible,
          inherited,
          icon,
          ports
        } = item;
        const scale =
          (Math.abs(extent[0][0]) + Math.abs(extent[1][0])) /
          (Math.abs(coordSysExtent[0][0]) + Math.abs(coordSysExtent[1][0]));
        const flip = this.getFlip(extent);
        const node = {
          tag: 'g',
          attr: {
            class: type,
            id: ident,
            isconnector: connectable,
            category: typeName,
            description: description || '',
            extensible,
            inherited,
            rotation,
            flip,
            transform: this.getTransform(scale, rotation, origin, flip)
          },
          children: []
        };
        // 计算图形的缩放
        const newScale = prevscale * scale;
        if (icon) {
          icon.forEach(i => {
            const { origin, rotation } = i;
            node.children.push({
              tag: 'g',
              attr: {
                transform: this.getTransform(1, rotation, origin)
              },
              children: this.parseShape(i, newScale)
            });
          });
        }
        if (ports) {
          node.children = node.children.concat(
            this.parseComponents(ports, 'port', newScale)
          );
        }
        nodes.push(node);
      }
    });
    return nodes;
  }

  // 解析图形
  parseDiagrams(diagrams) {
    let nodes = [];
    diagrams.forEach(item => {
      const { origin, rotation } = item;
      nodes.push({
        tag: 'g',
        attr: {
          class: 'diagram',
          transform: this.getTransform(1, rotation, origin),
          rotation
        },
        children: this.parseShape(item)
      });
    });
    return nodes;
  }

  // 解析连线
  parseConnects(connects) {
    let nodes = [];
    connects.forEach(item => {
      item.line.shapeType = 'line';
      const { port1, port2, line } = item;
      const { origin, rotation } = line;
      nodes.push({
        tag: 'g',
        attr: {
          class: 'connect',
          transform: this.getTransform(1, rotation, origin),
          source: port1,
          target: port2
        },
        children: this.parseShape(item.line)
      });
    });
    return nodes;
  }

  // 解析形状
  parseShape(shape, scale = 1) {
    if (!shape.visible) {
      return [];
    }
    const {
      shapeType,
      startAngle,
      endAngle,
      fillColor,
      fillPattern,
      lineColor,
      linePattern,
      lineTickness,
      lineSmooth,
      radius,
      text,
      fontName,
      fontUnit,
      fontSize,
      borderPattern
    } = shape;
    let temp = {
      tag: shapeType,
      text,
      attr: {},
      style: {
        fillColor: this.colorRGB2Hex(fillColor),
        fillPattern,
        lineColor: this.colorRGB2Hex(lineColor),
        linePattern,
        lineTickness,
        borderPattern,
        fontName,
        fontUnit,
        fontSize
      }
    };
    temp.style.lineTickness = temp.style.lineTickness / scale;
    const points = shape.points.map(point => {
      point[1] = -point[1];
      return point;
    });
    // 区分圆和椭圆
    if (shapeType == 'ellipse') {
      const rx = Math.abs(points[1][0] - points[0][0]) / 2;
      const ry = Math.abs(points[1][1] - points[0][1]) / 2;
      temp.attr.cx = 0;
      temp.attr.cy = 0;
      // 扇形
      if (startAngle && endAngle) {
        temp.tag = 'circle';
        temp.attr.r = rx;
        const dasharray = 2 * rx * Math.PI;
        const dashoffset = (dasharray / 360) * (360 - (endAngle - startAngle));
        const angle = -startAngle;
        temp.style.transform = 'rotate(' + angle + ',' + x + ',' + y + ')';
        temp.style.strokeDasharray = dasharray;
        temp.style.strokeDashoffset = dashoffset;
      } else {
        temp.attr.rx = rx;
        temp.attr.ry = ry;
      }
    }
    // 多边形
    if (shapeType == 'polygon') {
      temp.attr.points = points.join(' ');
    }
    // 矩形
    if (shapeType == 'rectangle') {
      // 计算左上角坐标
      const pointsX = points.map(point => {
        return point[0];
      });
      const pointsY = points.map(point => {
        return point[1];
      });
      temp.tag = 'rect';
      temp.attr.x = Math.min(...pointsX);
      temp.attr.y = Math.min(...pointsY);
      temp.attr.width = Math.abs(pointsX[1] - pointsX[0]);
      temp.attr.height = Math.abs(pointsY[1] - pointsY[0]);
      temp.attr.rx = radius;
      temp.attr.ry = radius;
    }
    // 线条
    if (shapeType == 'line') {
      temp.tag = 'path';
      if (lineSmooth == 'none') {
        temp.attr.d = `M${points[0].join(',')} L${points.slice(1).join(' ')}`;
      } else {
        temp.attr.d = `M${points[0].join(',')} S${points.slice(1).join(' ')}`;
      }
    }
    // 文本
    if (shapeType == 'text') {
      // 计算文字宽高
      const width = Math.abs(points[1][0] - points[0][0]);
      const height = Math.abs(points[1][1] - points[0][1]);
      temp.xlink = points.join(' ');
      temp.attr['text-anchor'] = 'middle';
      temp.attr['font-family'] = fontName || 'Arial,宋体';
      temp.attr['font-size'] = getTextSize(
        text,
        width,
        height,
        fontName,
        fontUnit
      );
    }
    // 图片
    if (shapeType == 'bitmap') {
      if (!text) {
        return;
      }
      const url = text.replace('data:image/svg', 'data:image/svg+xml');
      const width = Math.abs(points[1][0] - points[0][0]);
      const height = Math.abs(points[1][1] - points[0][1]);
      const pointsX = points.map(point => {
        return point[0];
      });
      const pointsY = points.map(point => {
        return point[1];
      });
      temp.tag = 'image';
      temp.attr.x = Math.min(...pointsX);
      temp.attr.y = Math.min(...pointsY);
      temp.attr.width = width;
      temp.attr.height = height;
      temp.attr.xlink = url;
    }
    return [temp];
  }

  // 绘图
  draw(nodes, root) {
    try {
      nodes.forEach(item => {
        const { tag, children, attr, style, text } = item;
        const node = createElement(tag);
        const keys = Object.keys(attr);
        keys.forEach(key => {
          const value = attr[key];
          if (value !== undefined) {
            node.setAttribute(key, value);
          }
        });
        if (tag == 'text') {
          style.linePattern = '';
          style.fillPattern = 'solid';
          style.fillColor = style.lineColor;
          // const textPath = createElement('textPath', node);
          // textPath.setAttribute('startOffset', '50%')
          node.textContent = text;
          const matrix = root.getAttribute('transform');
          let matrixVal = matrix.substring(7, matrix.length - 1).split(',');
          const parentFlip = root.parentNode.getAttribute('flip');
          const parentRotation = Number(
            root.parentNode.getAttribute('rotation')
          );
          if (parentFlip == 'h' || parentFlip == 'both') {
            matrixVal[0] = -matrixVal[0];
            matrixVal[1] = -matrixVal[1];
          }
          if (parentFlip == 'v' || parentFlip == 'both') {
            matrixVal[2] = -matrixVal[2];
            matrixVal[3] = -matrixVal[3];
          }
          if (parentRotation && parentRotation % 180 == 0) {
            matrixVal[0] = -matrixVal[0];
            matrixVal[1] = -matrixVal[1];
            matrixVal[2] = -matrixVal[2];
            matrixVal[3] = -matrixVal[3];
          }
          root.setAttribute('transform', `matrix(${matrixVal.join(',')})`);
        }
        if (style) {
          this.updateStroke(node, style);
          this.updateFill(node, style);
        }
        root.appendChild(node);
        if (children) {
          this.draw(children, node);
        }
      });
    } catch (err) {
      console.log(err, 'err');
    }
  }

  // 文本
  drawText() {
    const node = createElement('text');
    let { x, y, text, lineColor, fontName, fontSize } = this.nodeData;
    if (text == 'name') {
      return;
    }
    node.textContent = text;
    node.setAttribute('x', x);
    node.setAttribute('y', y);
    node.setAttribute('font-family', fontName);
    node.setAttribute('font-size', fontSize);
    node.setAttribute('text-anchor', 'middle');
    node.setAttribute('dominant-baseline', 'central');
    // node.setAttribute('font-weight', 'bold');
    node.setAttribute('fill', lineColor);
    this.node = node;
    this.addNode(false, false);
  }

  // 图片
  drawImage() {
    let { x, y, width, height, xlink } = this.nodeData;
    if (!text) {
      return;
    }
    const node = createElement('image');
    node.setAttribute('x', x);
    node.setAttribute('y', y);
    node.setAttribute('width', width);
    node.setAttribute('height', height);
    node.setAttribute('xlink:href', xlink);
    this.node = node;
    this.addNode(true, true);
  }

  // 边框模式
  updateStroke(node, style) {
    const { linePattern, lineTickness, lineColor } = style;
    if (!linePattern) {
      return;
    }
    if (lineTickness) {
      node.style['stroke-width'] = lineTickness;
    }
    switch (linePattern) {
      case 'solid':
        node.style['stroke'] = lineColor;
        break;
      case 'dashDotDotLine':
        node.style['stroke-dasharray'] = '4,2,2,2,2,2';
        break;
      case 'dashDot':
        node.style['stroke-dasharray'] = '4,2,2,2';
        break;
      case 'dot':
        node.style['stroke-dasharray'] = '1,1';
        break;
      case 'dash':
        node.style['stroke-dasharray'] = '3,3';
        break;
      default:
        node.style['stroke'] = 'none';
    }
  }

  // 填充模式
  updateFill(node, style) {
    const def = this.defs;
    const { fillPattern, fillColor, lineColor } = style;
    if (!fillPattern || fillPattern === 'none') {
      node.style['fill'] = 'none';
    } else if (fillPattern === 'solid') {
      node.style['fill'] = fillColor;
    } else if (fillPattern === 'horizontalCyliner') {
      const colors = [
        {
          color: lineColor,
          offset: 0,
          opacity: 1
        },
        {
          color: fillColor,
          offset: 0.5,
          opacity: 1
        },
        {
          color: lineColor,
          offset: 1,
          opacity: 1
        }
      ];
      const gradient = createLinearGradient(colors, 'north');
      const id = gradient.getAttribute('id');
      def.appendChild(gradient);
      node.style['fill'] = 'url(' + '#' + id + ')';
    } else if (fillPattern === 'verticalCylinder') {
      const colors = [
        {
          color: lineColor,
          offset: 0,
          opacity: 1
        },
        {
          color: fillColor,
          offset: 0.5,
          opacity: 1
        },
        {
          color: lineColor,
          offset: 1,
          opacity: 1
        }
      ];
      const gradient = createLinearGradient(colors, 'west');
      const id = gradient.getAttribute('id');
      def.appendChild(gradient);
      node.style['fill'] = 'url(' + '#' + id + ')';
    } else if (fillPattern === 'sphere') {
      const colors = [
        {
          color: fillColor,
          offset: 0,
          opacity: 1
        },
        {
          color: lineColor,
          offset: 1,
          opacity: 1
        }
      ];
      const gradient = createRadialGradient(colors);
      const id = gradient.getAttribute('id');
      def.appendChild(gradient);
      node.style['fill'] = 'url(' + '#' + id + ')';
    } else {
      const pattern = createPattern(fillPattern, fillColor, lineColor);
      const id = pattern.getAttribute('id');
      def.appendChild(pattern);
      node.style['fill'] = 'url(' + '#' + id + ')';
    }
  }

  // svg转成xml字符串
  svgToString(root) {
    return new XMLSerializer().serializeToString(root);
  }

  // xml转成base64
  svgToBase64(root) {
    const svg = createElement('svg');
    svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('version', '1.2');
    svg.setAttribute('width', this.width);
    svg.setAttribute('height', this.height);
    svg.appendChild(root);
    const url =
      'data:image/svg+xml;charset=utf-8,' +
      encodeURIComponent(this.svgToString(svg));
    return url;
  }

  // 计算矩阵
  getTransform(scale, angle, origin, flip) {
    try {
      if (origin) {
        const tx = parseFloat(origin[0].toFixed(2));
        const ty = parseFloat(origin[1].toFixed(2));
        const hudu = -((2 * Math.PI) / 360) * angle; // 弧度，客户端的旋转角度是逆时针旋转为正
        let m1 = this.format(scale * Math.cos(hudu));
        let m2 = this.format(scale * Math.sin(hudu));
        let m3 = -this.format(scale * Math.sin(hudu));
        let m4 = this.format(scale * Math.cos(hudu));
        if (flip == 'h' || flip == 'both') {
          m1 = -m1;
          m2 = -m2;
        }
        if (flip == 'v' || flip == 'both') {
          m3 = -m3;
          m4 = -m4;
        }
        return `matrix(${m1},${m2},${m3},${m4},${tx},${-ty})`;
      }
    } catch (err) {
      console.log(err, 'err');
    }
  }

  // 根据extent判断是否有水平翻转和垂直翻转
  getFlip(extent) {
    // 正常是左下和右上的坐标
    try {
      const left = extent[0];
      const right = extent[1];
      if (left[0] < right[0] && left[1] < right[1]) {
        return 'none';
      } else if (left[0] > right[0] && left[1] < right[1]) {
        return 'h';
      } else if (left[0] < right[0] && left[1] > right[1]) {
        return 'v';
      } else {
        return 'both';
      }
    } catch (err) {
      console.log(err, 'err');
    }
  }

  // 科学计数法转换
  format(value) {
    return parseFloat(Number(Number(value).toLocaleString()).toFixed(2));
  }

  // 颜色转换
  colorRGB2Hex(color) {
    if (color && color.length) {
      var rgb = color;
      var r = parseInt(rgb[0]);
      var g = parseInt(rgb[1]);
      var b = parseInt(rgb[2]);
      var hex =
        '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      return hex;
    }
    return '';
  }
}
